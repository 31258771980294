<template>
  <div style="background: #fff" :class="disabled ? 'disableGray' : ''">
    <div class="free-header">
      <img src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/extendInfo/lease.png" width="100%" height="auto" />
    </div>
    <van-form @submit="onSubmit">
      <div class="all-contaions">
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">您的房源情况</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :disabled="disabled"
            v-model="infoFrom.firstName"
            required
            :rules="[{ required: true }]"
            label="物业地址"
            placeholder="请输入物业地址"
          />
          <van-field
            :disabled="disabled"
            :value="infoFroms.roomName"
            readonly
            required
            :rules="[{ required: true }]"
            label="选择房型"
            @click="isRoom = !disabled"
            placeholder="请选择户型"
            right-icon="arrow"
          />
          <van-popup v-model="isRoom" position="bottom">
            <van-picker
              show-toolbar
              title="房型选择"
              @confirm="confirmRoom"
              @cancel="isRoom = false"
              :columns="roomOption"
            />
          </van-popup>
          <!-- 户型选择 -->
          <van-field
            :disabled="disabled"
            :value="infoFroms.houseTypeNme"
            readonly
            required
            :rules="[{ required: true }]"
            label="选择户型"
            @click="isHouseType = !disabled"
            placeholder="请选择户型"
            right-icon="arrow"
          />
          <van-popup v-model="isHouseType" position="bottom">
            <van-picker
              show-toolbar
              :columns="houseOption"
              @confirm="onConfirmHouse"
              @cancel="isHouseType = false"
            />
          </van-popup>
          <van-cell required is-link class="van-cell-tag">
            <van-field
              :disabled="disabled"
              placeholder="请输入你的预算"
              type="number"
              :rules="[{ required: true }]"
              v-model="infoFrom.moneyOfMonthly"
            ></van-field>
            <template #title>
              <span class="custom-title grayColor">每周房租金额</span>
              <van-tag class="custom-tag grayColor" type="warning">{{
                monetarySymbol
              }}</van-tag>
            </template>
          </van-cell>
          <van-cell required class="van-cell-tags">
            <van-field
              :disabled="disabled"
              placeholder="开始时间"
              :rules="[{ required: true }]"
              :value="infoFrom.rentStartTime"
              readonly
              right-icon="notes-o"
              @click="isRentStrat = !disabled"
            ></van-field>
            <div class="hh">-</div>
            <van-field
              :disabled="disabled"
              placeholder="结束时间"
              :rules="[{ required: true }]"
              :value="infoFrom.rentEndTime"
              readonly
              right-icon="notes-o"
              @click="isRentEnd = !disabled"
            ></van-field>
            <template #title>
              <span class="grayColor">租期情况</span>
            </template>
          </van-cell>
          <!-- 租期开始时间 -->
        </van-cell-group>
        <van-popup v-model="isRentStrat" position="bottom">
          <van-datetime-picker
            type="date"
            title="选择年月日"
            v-model="currentDate"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="confirmStart"
            @cancel="isRentStrat = false"
          />
        </van-popup>
        <!-- 租期结束时间 -->
        <van-popup v-model="isRentEnd" position="bottom">
          <van-datetime-picker
            type="date"
            title="选择年月日"
            v-model="currentDate"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="confirmEnd"
            @cancel="isRentEnd = false"
          />
        </van-popup>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">您的招租要求</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :disabled="disabled"
            :value="infoFroms.sexName"
            required
            readonly
            :rules="[{ required: true }]"
            label="租客性别"
            @click="isSexShow = !disabled"
            placeholder="请选择租客性别"
            right-icon="arrow"
          />
          <!-- 性别 -->
          <van-popup v-model="isSexShow" position="bottom">
            <van-picker
              show-toolbar
              :columns="rentSexOption"
              @cancel="isSexShow = false"
              @confirm="onConfirmSex"
            />
          </van-popup>
          <van-field
            :disabled="disabled"
            :value="infoFroms.whetherStudentName"
            readonly
            required
            :rules="[{ required: true }]"
            label="是否接受学生"
            @click="whetherStudent = !disabled"
            placeholder="请选择"
            right-icon="arrow"
          />
          <!-- 是否接受学生 -->
          <van-popup v-model="whetherStudent" position="bottom">
            <van-picker
              show-toolbar
              :columns="AllOption"
              @cancel="whetherStudent = false"
              @confirm="onConfirmStudent"
            />
          </van-popup>
          <!-- 是否养宠物 -->
          <van-field
            :disabled="disabled"
            :value="infoFroms.whetherPetName"
            readonly
            required
            :rules="[{ required: true }]"
            label="是否养宠物"
            @click="whetherPet = !disabled"
            placeholder="请选择"
            right-icon="arrow"
          />
          <van-popup v-model="whetherPet" position="bottom">
            <van-picker
              show-toolbar
              :columns="permitOption"
              @cancel="whetherPet = false"
              @confirm="onConfirmPet"
            />
          </van-popup>
          <div class="cell-group">
            <van-cell class="grayColor" title="补充要求" />
            <van-field
              :disabled="disabled"
              class="supplementary"
              v-model="infoFrom.otherRequire"
              rows="1"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="如果有其他要求，请填写在此处"
              show-word-limit
            />
          </div>
          <div class="message">
            如遇到问题请至邮件info@clozhome或您的顾问获取帮助
          </div>
        </van-cell-group>
      </div>

      <div class="spacing-height"></div>
      <div class="bottom-flex" v-if="!disabled">
        <div class="flex-left">
          <div class="left-consult" @click="serviceClick">
            <van-image
              width="23"
              height="20"
              fit="scale-down"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/consult.png"
            /><br />咨询客服
          </div>
        </div>
        <div class="flex-right">
          <van-button v-if="disabled" class="default" type="default"
            >立即提交</van-button
          >
          <van-button
            v-if="!disabled"
            class="primary"
            type="primary"
            native-type="submit"
            >立即提交</van-button
          >
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  addUpdateApply,
  getUpdateApplyStatus,
  getProductUpdateInfo,
} from "@/api/informations/information";
import { timeStampChange, transform, transformArray } from "@/utils/index";
import Data from "@/utils/options";
export default {
  name: "LeaseService",
  data() {
    return {
      infoFrom: {},
      infoFroms: {},
      product: {},
      disabled: false,
      roomOption: Data.roomOption(),
      houseOption: Data.houseOption(),
      rentSexOption: Data.rentSexOption(),
      AllOption: Data.AllOption(),
      permitOption: Data.permitOption(),
      minDate: new Date(1910, 0, 1),
      maxDate: new Date(2080, 10, 1),
      currentDate: new Date(),
      isHouseType: false,
      isRoom: false,
      isRentStrat: false,
      isRentEnd: false,
      isSexShow: false,
      whetherStudent: false,
      whetherPet: false,
      processId: undefined,
      monetarySymbol: "AUD",
      env: "",
    };
  },
  created() {
    this.processId = this.$route.query.processId;
    this.onload();
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () {
        uni.getEnv(function (res) {
          that.env = res;
          console.log("当前环境：" + JSON.stringify(res));
        });
      });
    });
  },
  methods: {
    onload() {
      // this.disabled = true;
      getUpdateApplyStatus({ processId: this.processId }).then((res) => {
        if (res.data) {
          this.monetarySymbol = res.data.monetarySymbol;
          if (res.data.status == 1) {
            this.disabled = true;
            this.infoFrom = JSON.parse(res.data.updateInfo);
            let data = this.infoFrom;
            this.infoFroms.roomName = transformArray(
              this.roomOption,
              data.bedroomNum,
              data.toiletNum,
              data.parkNum
            );
            this.infoFroms.houseTypeNme = transform(
              this.houseOption,
              data.houseType
            );
            this.infoFroms.sexName = transform(
              this.rentSexOption,
              data.rentSex
            );
            this.infoFroms.whetherStudentName = transform(
              this.AllOption,
              data.whetherStudent
            );
            this.infoFroms.whetherPetName = transform(
              this.permitOption,
              data.whetherPet
            );
          }
          this.getProductUpdateInfo(res.data.productId);
        }
      });
    },
    getProductUpdateInfo(productId) {
      getProductUpdateInfo({ productId: productId }).then((res) => {
        console.log(res);
        this.product = res.data;
      });
    },
    confirmRoom(value, index) {
      this.infoFroms.roomName = value[0] + value[1] + value[2];
      this.infoFrom.bedroomNum = index[0];
      this.infoFrom.toiletNum = index[1];
      this.infoFrom.parkNum = index[2];
      this.isRoom = false;
    },
    onConfirmHouse(value, index) {
      this.infoFroms.houseTypeNme = value;
      this.infoFrom.houseType = index + 1;
      this.isHouseType = false;
    },
    confirmStart(data) {
      this.infoFrom.rentStartTime = timeStampChange(data);
      this.isRentStrat = false;
    },
    confirmEnd(data) {
      this.infoFrom.rentEndTime = timeStampChange(data);
      this.isRentEnd = false;
    },
    onConfirmSex(value, index) {
      this.infoFroms.sexName = value;
      this.infoFrom.rentSex = index + 1;
      this.isSexShow = false;
    },
    onConfirmStudent(value, index) {
      this.infoFroms.whetherStudentName = value;
      this.infoFrom.whetherStudent = index + 1;
      this.whetherStudent = false;
    },
    onConfirmPet(value, index) {
      this.infoFroms.whetherPetName = value;
      this.infoFrom.whetherPet = index + 1;
      this.whetherPet = false;
    },
    serviceClick() {
      console.log("点击联系客服===>");
      if (this.env.miniprogram) {
        uni.navigateTo({
          url: "/otherPages/webview/showContact",
        });
      } else {
        enterMeiQiaService.postMessage(
          JSON.stringify({
            serviceName: this.product.productName,
            applyType: this.product.updateApplyName,
          })
        );
      }
    },
    // 提交接口
    onSubmit() {
      var that = this;
      if (this.disabled) {
        return;
      }
      let obj = {
        updateApplyJson: JSON.stringify(this.infoFrom),
        processId: this.processId,
      };
      addUpdateApply(obj).then((res) => {
        console.log("提交补充表单结果===>", res);
        if (res.code == 0) {
          var obj2 = JSON.stringify({
            orderNo: res.data.orderNo,
            orderTime: res.data.orderTime,
            recordId: res.data.recordId,
          });
          var obj3 = encodeURIComponent(obj2);
          // if (that.env.miniprogram) {
          //   uni.navigateTo({
          //     url: `/applyPages/extendInfo?orders=${obj3}`,
          //   });
          // } else {
          //   enterApplyResult.postMessage(
          //     JSON.stringify({
          //       orderNo: res.data.orderNo,
          //       orderTime: res.data.orderTime,
          //       recordId: res.data.recordId,
          //     })
          //   );
          // }
          try {
            enterApplyResult.postMessage(
              JSON.stringify({
                orderNo: res.data.orderNo,
                orderTime: res.data.orderTime,
                recordId: res.data.recordId,
              })
            );
          } catch (error) {
            uni.navigateTo({
              url: `/applyPages/extendInfo?orders=${obj3}`,
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-flex {
  padding-top: 10px;
  padding-bottom: 10px;
}
/deep/.van-icon-notes-o::before {
  font-size: 22px;
}
.van-cell-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.van-cell-tags {
  display: flex;
  align-items: center;
}
.van-cell-tag /deep/.van-cell,
.van-cell-tags /deep/.van-cell {
  padding: 3px 0;
}
.van-cell-tags /deep/.van-cell::after {
  border-bottom: none;
}
.custom-title {
  margin-right: 10px;
}
.van-cell-tags /deep/.van-cell__title {
  webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
  width: 80px;
}
.van-cell-tags /deep/.van-cell__value {
  display: flex;
}
.hh {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
.cell-group /deep/.van-cell::after {
  border-bottom: none;
}
.supplementary {
  border: 1px solid #ebedf0;
  width: 90%;
  margin: 0 5%;
  border-radius: 5px;
}
/deep/.van-button {
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}
.flex-right {
  border-radius: 4px;
  overflow: hidden;
}
.disableGray {
  .grayColor {
    color: #c8c9cc;
  }
}
</style>
